<template>
  <div class="saleProfit">
    <h1>精细利润核算</h1>
    <el-form :inline="true">
      <el-form-item label="取样日期">
        <el-date-picker v-model="searchParam.sampleDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="客户">
        <el-select v-model="searchParam.custom" filterable style="width: 120px;" clearable>
          <el-option v-for='customItem in show.customList' :key="customItem.id" :label="customItem.value" :value="customItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="仓储">
        <el-select v-model="searchParam.storage" filterable style="width: 120px;" clearable>
          <el-option v-for="storageItem in show.storageList" :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="运输公司">
        <el-select v-model="searchParam.transportCompany" filterable style="width: 120px;" clearable>
          <el-option v-for="transportCompanyItem in show.transportCompanyList" :key="transportCompanyItem.id" :label="transportCompanyItem.value" :value="transportCompanyItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button @click="useFormula">应用公式</el-button>
          <el-button type="primary" @click="doProfit()">保存核算</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-text type="danger" size="small">*上列为以往核算的结果，下列为本次核算的结果</el-text>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" border show-summary :summary-method="summary" style="height: calc(100vh - 310px);">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column prop="saleSample.sendDate" label="发货日期" width="120" show-overflow-tooltip />
      <el-table-column prop="saleSample.sampleDate" label="取样日期" width="120" show-overflow-tooltip />
      <el-table-column prop="saleSample.transportCompany" label="运输公司" width="120" show-overflow-tooltip />
      <el-table-column prop="saleSample.truckNumbers" label="车号" width="120" show-overflow-tooltip />
      <el-table-column prop="saleSample.storage" label="仓储" width="120" show-overflow-tooltip />
      <el-table-column prop="saleSample.customNote" label="销售品名" width="120" show-overflow-tooltip />
      <el-table-column prop="saleSample.custom" label="客户" width="120" show-overflow-tooltip />
      <el-table-column prop="saleSample.sampleQuantity" label="数量" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.sampleQuantity) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleMixList" label="配比" width="280" show-overflow-tooltip>
        <template #default="scope">
          <el-text style="margin-right: 10px;" v-for="item in scope.row.saleMixList" :key="item">{{ item }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.saleAmount" label="结算金额" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.saleAmount) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.quanliu" label="全硫" width="120" show-overflow-tooltip />
      <el-table-column prop="saleSample.diweifareliangKc" label="热值大卡" width="120" show-overflow-tooltip />
      <el-table-column prop="saleSample.procureProductPrice" label="入库单价" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.procureProductPrice) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.procureProductPrice && scope.row.saleSample.procureProductPrice != scope.row.saleSampleUpdater.procureProductPrice ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.procureProductPrice) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.procureTransportPrice" label="上游运输单价" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.procureTransportPrice) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.procureTransportPrice && scope.row.saleSample.procureTransportPrice != scope.row.saleSampleUpdater.procureTransportPrice ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.procureTransportPrice) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.saleTransportPrice" label="下游运输单价" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.saleTransportPrice) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.saleTransportPrice && scope.row.saleSample.saleTransportPrice != scope.row.saleSampleUpdater.saleTransportPrice ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.saleTransportPrice) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.dispatchPrice" label="调度费单价" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.dispatchPrice) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.dispatchPrice && scope.row.saleSample.dispatchPrice != scope.row.saleSampleUpdater.dispatchPrice ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.dispatchPrice) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.sumCostPrice" label="单位合计成本" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.sumCostPrice) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.sumCostPrice && scope.row.saleSample.sumCostPrice != scope.row.saleSampleUpdater.sumCostPrice ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.sumCostPrice) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.costPriceUntax" label="单位未税成本" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.costPriceUntax) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.costPriceUntax && scope.row.saleSample.costPriceUntax != scope.row.saleSampleUpdater.costPriceUntax ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.costPriceUntax) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.costPriceTax" label="单位成本税" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.costPriceTax) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.costPriceTax && scope.row.saleSample.costPriceTax != scope.row.saleSampleUpdater.costPriceTax ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.costPriceTax) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.salePrice" label="销售单价" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.salePrice) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.salePrice && scope.row.saleSample.salePrice != scope.row.saleSampleUpdater.salePrice ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.salePrice) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.salePriceUntax" label="单位未税价" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.salePriceUntax) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.salePriceUntax && scope.row.saleSample.salePriceUntax != scope.row.saleSampleUpdater.salePriceUntax ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.salePriceUntax) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.salePriceXiaoxiangTax" label="单位销项税" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.salePriceXiaoxiangTax) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.salePriceXiaoxiangTax && scope.row.saleSample.salePriceXiaoxiangTax != scope.row.saleSampleUpdater.salePriceXiaoxiangTax ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.salePriceXiaoxiangTax) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.salePriceZengzhiTax" label="单位增值税" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.salePriceZengzhiTax) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.salePriceZengzhiTax && scope.row.saleSample.salePriceZengzhiTax != scope.row.saleSampleUpdater.salePriceZengzhiTax ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.salePriceZengzhiTax) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.salePriceFujiaTax" label="单位附加税" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.salePriceFujiaTax) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.salePriceFujiaTax && scope.row.saleSample.salePriceFujiaTax != scope.row.saleSampleUpdater.salePriceFujiaTax ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.salePriceFujiaTax) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.salePriceYinhuaTax" label="单位印花税" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.salePriceYinhuaTax) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.salePriceYinhuaTax && scope.row.saleSample.salePriceYinhuaTax != scope.row.saleSampleUpdater.salePriceYinhuaTax ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.salePriceYinhuaTax) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.salePriceEstimatedCost" label="单位预估费用" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.salePriceEstimatedCost) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.salePriceEstimatedCost && scope.row.saleSample.salePriceEstimatedCost != scope.row.saleSampleUpdater.salePriceEstimatedCost ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.salePriceEstimatedCost) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.salePriceSuodeTax" label="单位所得税" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.salePriceSuodeTax) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.salePriceSuodeTax && scope.row.saleSample.salePriceSuodeTax != scope.row.saleSampleUpdater.salePriceSuodeTax ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.salePriceSuodeTax) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.sumSalePriceTax" label="单位合计税费" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.sumSalePriceTax) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.sumSalePriceTax && scope.row.saleSample.sumSalePriceTax != scope.row.saleSampleUpdater.sumSalePriceTax ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.sumSalePriceTax) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="zengzhiTaxBurden" label="增值税税负" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ scope.row.saleSample.zengzhiTaxBurden ? (math.multiply(scope.row.saleSample.zengzhiTaxBurden, 100) + '%') : '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.zengzhiTaxBurden && scope.row.saleSample.zengzhiTaxBurden != scope.row.saleSampleUpdater.zengzhiTaxBurden ? 'danger' : 'success'">
            {{ scope.row.saleSampleUpdater.zengzhiTaxBurden ? (math.multiply(scope.row.saleSampleUpdater.zengzhiTaxBurden, 100) + '%') : '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.saleProfitPrice" label="单位销售利润" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.saleProfitPrice) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.saleProfitPrice && scope.row.saleSample.saleProfitPrice != scope.row.saleSampleUpdater.saleProfitPrice ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.saleProfitPrice) || '-' }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column prop="saleSample.saleProfitAmount" label="销售利润金额" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text>{{ math.formatNumber(scope.row.saleSample.saleProfitAmount) || '-' }}</el-text>
          <br>
          <el-text :type="scope.row.saleSampleUpdater.saleProfitAmount && scope.row.saleSample.saleProfitAmount != scope.row.saleSampleUpdater.saleProfitAmount ? 'danger' : 'success'">
            {{ math.formatNumber(scope.row.saleSampleUpdater.saleProfitAmount) || '-' }}
          </el-text>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <el-dialog v-model="show.formulaDialog" title="选择公式" width="500">
    <el-form label-width="auto">
      <el-form-item label="调度费">
        <el-select v-model="formulas.dispatchPrice" filterable style="width: 350px;" clearable>
          <el-option v-for='item in show.formulas.dispatchPrice' :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="成本税">
        <el-select v-model="formulas.costPriceTax" filterable style="width: 350px;" clearable>
          <el-option v-for='item in show.formulas.costPriceTax' :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="销项税">
        <el-select v-model="formulas.salePriceXiaoxiangTax" filterable style="width: 350px;" clearable>
          <el-option v-for='item in show.formulas.salePriceXiaoxiangTax' :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="附加税">
        <el-select v-model="formulas.salePriceFujiaTax" filterable style="width: 350px;" clearable>
          <el-option v-for='item in show.formulas.salePriceFujiaTax' :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="印花税">
        <el-select v-model="formulas.salePriceYinhuaTax" filterable style="width: 350px;" clearable>
          <el-option v-for='item in show.formulas.salePriceYinhuaTax' :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="预估费用">
        <el-select v-model="formulas.salePriceEstimatedCost" filterable style="width: 350px;" clearable>
          <el-option v-for='item in show.formulas.salePriceEstimatedCost' :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="所得税">
        <el-select v-model="formulas.salePriceSuodeTax" filterable style="width: 350px;" clearable>
          <el-option v-for='item in show.formulas.salePriceSuodeTax' :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="doFormula()">
          应用公式
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  list: [],
  sampleQuantity: null,
  saleAmount: null,
  saleProfitAmount: null,
})

const formulas = reactive({})

const show = reactive({
  selected: [],
  customList: [],
  storageList: [],
  transportCompanyList: [],
  formulaDialog: false,
  formulas: {
    dispatchPrice: [],
    costPriceTax: [],
    salePriceXiaoxiangTax: [],
    salePriceFujiaTax: [],
    salePriceYinhuaTax: [],
    salePriceEstimatedCost: [],
    salePriceSuodeTax: [],
  }
})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'transportCompany' } }).then(res => {
  show.transportCompanyList = res.dictList
})
api.get('/backend/formula/get', { params: { model: 'dispatchPrice' } }).then(res => {
  show.formulas.dispatchPrice = res.list
})
api.get('/backend/formula/get', { params: { model: 'costPriceTax' } }).then(res => {
  show.formulas.costPriceTax = res.list
})
api.get('/backend/formula/get', { params: { model: 'salePriceXiaoxiangTax' } }).then(res => {
  show.formulas.salePriceXiaoxiangTax = res.list
})
api.get('/backend/formula/get', { params: { model: 'salePriceFujiaTax' } }).then(res => {
  show.formulas.salePriceFujiaTax = res.list
})
api.get('/backend/formula/get', { params: { model: 'salePriceYinhuaTax' } }).then(res => {
  show.formulas.salePriceYinhuaTax = res.list
})
api.get('/backend/formula/get', { params: { model: 'salePriceEstimatedCost' } }).then(res => {
  show.formulas.salePriceEstimatedCost = res.list
})
api.get('/backend/formula/get', { params: { model: 'salePriceSuodeTax' } }).then(res => {
  show.formulas.salePriceSuodeTax = res.list
})

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.sampleDates = []
  searchParam.custom = null
  searchParam.storage = null
  searchParam.transportCompany = null
}
resetSearchParam()

const commitFindList = () => {
  const params = {}
  if (!searchParam.sampleDates || searchParam.sampleDates.length != 2) {
    ElMessage.error('请先选择时间区间')
    return
  }
  params.sampleDateGe = util.parseTime(searchParam.sampleDates[0], '{y}-{m}-{d}')
  params.sampleDateLe = util.parseTime(searchParam.sampleDates[1], '{y}-{m}-{d}')
  params.custom = searchParam.custom
  params.storage = searchParam.storage
  params.transportCompany = searchParam.transportCompany
  api.post('/backend/saleSample/getProfit', params).then(res => {
    for (const i in res.list) {
      const itemProfit = res.list[i]
      itemProfit.saleSampleUpdater = {}
      itemProfit.saleSampleUpdater.procureProductPrice = math.halfUp(itemProfit.procureProductAmount / itemProfit.saleSample.sampleQuantity, 2)
      itemProfit.saleSampleUpdater.procureTransportPrice = math.halfUp(itemProfit.procureTransportAmount / itemProfit.saleSample.sampleQuantity, 2)
      itemProfit.saleSampleUpdater.saleTransportPrice = math.halfUp(itemProfit.saleTransportAmount / itemProfit.saleSample.sampleQuantity, 2)
      itemProfit.saleSampleUpdater.salePrice = math.halfUp(itemProfit.saleSample.saleAmount / itemProfit.saleSample.sampleQuantity, 2)
    }
    data.list = res.list
    data.sampleQuantity = res.sampleQuantity
    data.saleAmount = res.saleAmount
    data.saleProfitAmount = res.saleProfitAmount
  })
}

const summary = () => {
  return ['合计', '', '', '', '', '', '', '', math.formatNumber(data.sampleQuantity), '', math.formatNumber(data.saleAmount), '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', math.formatNumber(data.saleProfitAmount)]
}

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const useFormula = () => {
  const selecteds = tabRef.value.getSelectionRows()
  if (selecteds.length <= 0) {
    ElMessage.error('请选择要核算的结算单')
    return
  }
  if (selecteds.filter(item => !item.saleSample || !item.saleSample.saleSettlementId).length > 0) {
    ElMessage.error('有结算单未结算，请先结算再核算利润')
    return
  }
  show.formulaDialog = true
  // dispatchPrice: [],
  //   costPriceTax: [],
  //   salePriceXiaoxiangTax: [],
  //   salePriceFujiaTax: [],
  //   salePriceYinhuaTax: [],
  //   salePriceEstimatedCost: [],
  //   salePriceSuodeTax: [],
  let autoed = false
  if (show.formulas.dispatchPrice.length == 1) {
    formulas.dispatchPrice = show.formulas.dispatchPrice[0].id
    autoed = true
  }
  if (show.formulas.costPriceTax.length == 1) {
    formulas.costPriceTax = show.formulas.costPriceTax[0].id
    autoed = true
  }
  if (show.formulas.salePriceXiaoxiangTax.length == 1) {
    formulas.salePriceXiaoxiangTax = show.formulas.salePriceXiaoxiangTax[0].id
    autoed = true
  }
  if (show.formulas.salePriceFujiaTax.length == 1) {
    formulas.salePriceFujiaTax = show.formulas.salePriceFujiaTax[0].id
    autoed = true
  }
  if (show.formulas.salePriceYinhuaTax.length == 1) {
    formulas.salePriceYinhuaTax = show.formulas.salePriceYinhuaTax[0].id
    autoed = true
  }
  if (show.formulas.salePriceEstimatedCost.length == 1) {
    formulas.salePriceEstimatedCost = show.formulas.salePriceEstimatedCost[0].id
    autoed = true
  }
  if (show.formulas.salePriceSuodeTax.length == 1) {
    formulas.salePriceSuodeTax = show.formulas.salePriceSuodeTax[0].id
    autoed = true
  }
  if (autoed) {
    ElMessage.warning('自动选择只有一个选项的公式，请确认公式是否正确')
  }
}

const doFormula = () => {
  if (!formulas.dispatchPrice || !formulas.costPriceTax || !formulas.salePriceXiaoxiangTax || !formulas.salePriceFujiaTax || !formulas.salePriceYinhuaTax || !formulas.salePriceEstimatedCost || !formulas.salePriceSuodeTax) {
    ElMessage.error('请选择公式')
    return
  }
  const selecteds = tabRef.value.getSelectionRows()
  if (selecteds.length <= 0) {
    ElMessage.error('请选择要核算的结算单')
    return
  }
  for (const i in selecteds) {
    const saleSample = selecteds[i].saleSample
    const item = selecteds[i].saleSampleUpdater
    const 数量 = saleSample.sampleQuantity
    const 结算费用 = saleSample.saleAmount
    const 结算单价 = item.salePrice
    const 入库单价 = item.procureProductPrice
    const 上游运费单价 = item.procureTransportPrice
    const 下游运费单价 = item.saleTransportPrice
    console.info('数量, 结算费用, 结算单价, 入库单价, 上游运费单价, 下游运费单价', 数量, 结算费用, 结算单价, 入库单价, 上游运费单价, 下游运费单价)
    {
      let 结果 = null
      const thisFormula = show.formulas.dispatchPrice.filter(x => x.id == formulas.dispatchPrice)
      if (thisFormula && thisFormula.length == 1) {
        eval(thisFormula[0].jsScript)
      }
      if (结果 === null) {
        ElMessage.error('调度费公式有误')
        return
      }
      item.dispatchPrice = math.halfUp(结果, 2)
    }
    const 调度费 = item.dispatchPrice
    console.info('调度费', 调度费)
    item.sumCostPrice = math.halfUp(item.procureProductPrice + item.procureTransportPrice + item.saleTransportPrice + item.dispatchPrice, 2)
    const 成本合计 = item.sumCostPrice
    console.info('成本合计', 成本合计)
    {
      let 结果 = null
      const thisFormula = show.formulas.costPriceTax.filter(x => x.id == formulas.costPriceTax)
      if (thisFormula && thisFormula.length == 1) {
        eval(thisFormula[0].jsScript)
      }
      if (结果 === null) {
        ElMessage.error('成本税公式有误')
        return
      }
      item.costPriceTax = math.halfUp(结果, 2)
    }
    const 成本税 = item.costPriceTax
    console.info('成本税', 成本税)
    item.costPriceUntax = math.halfUp(item.sumCostPrice - item.costPriceTax, 2)
    const 未税成本 = item.costPriceUntax
    console.info('未税成本', 未税成本)
    {
      let 结果 = null
      const thisFormula = show.formulas.salePriceXiaoxiangTax.filter(x => x.id == formulas.salePriceXiaoxiangTax)
      if (thisFormula && thisFormula.length == 1) {
        eval(thisFormula[0].jsScript)
      }
      if (结果 === null) {
        ElMessage.error('销项税公式有误')
        return
      }
      item.salePriceXiaoxiangTax = math.halfUp(结果, 2)
    }
    const 销项税 = item.salePriceXiaoxiangTax
    console.info('销项税', 销项税)
    item.salePriceZengzhiTax = math.halfUp(item.salePriceXiaoxiangTax - item.costPriceTax, 2)
    const 增值税 = item.salePriceZengzhiTax
    console.info('增值税', 增值税)
    item.salePriceUntax = math.halfUp(item.salePrice - item.salePriceXiaoxiangTax, 2)
    const 未税单价 = item.salePriceUntax
    console.info('未税单价', 未税单价)
    item.zengzhiTaxBurden = math.halfUp(item.salePriceZengzhiTax / item.salePriceUntax, 4)
    const 增值税税负 = item.zengzhiTaxBurden
    console.info('增值税税负', 增值税税负)
    {
      let 结果 = null
      const thisFormula = show.formulas.salePriceFujiaTax.filter(x => x.id == formulas.salePriceFujiaTax)
      if (thisFormula && thisFormula.length == 1) {
        eval(thisFormula[0].jsScript)
      }
      if (结果 === null) {
        ElMessage.error('附加税公式有误')
        return
      }
      item.salePriceFujiaTax = math.halfUp(结果, 2)
    }
    const 附加税 = item.salePriceFujiaTax
    console.info('附加税', 附加税)
    {
      let 结果 = null
      const thisFormula = show.formulas.salePriceYinhuaTax.filter(x => x.id == formulas.salePriceYinhuaTax)
      if (thisFormula && thisFormula.length == 1) {
        eval(thisFormula[0].jsScript)
      }
      if (结果 === null) {
        ElMessage.error('印花税公式有误')
        return
      }
      item.salePriceYinhuaTax = math.halfUp(结果, 2)
    }
    const 印花税 = item.salePriceYinhuaTax
    console.info('印花税', 印花税)
    {
      let 结果 = null
      const thisFormula = show.formulas.salePriceEstimatedCost.filter(x => x.id == formulas.salePriceEstimatedCost)
      if (thisFormula && thisFormula.length == 1) {
        eval(thisFormula[0].jsScript)
      }
      if (结果 === null) {
        ElMessage.error('预估费用公式有误')
        return
      }
      item.salePriceEstimatedCost = math.halfUp(结果, 2)
    }
    const 预估费用 = item.salePriceEstimatedCost
    console.info('预估费用', 预估费用)
    {
      let 结果 = null
      const thisFormula = show.formulas.salePriceSuodeTax.filter(x => x.id == formulas.salePriceSuodeTax)
      if (thisFormula && thisFormula.length == 1) {
        eval(thisFormula[0].jsScript)
      }
      if (结果 === null) {
        ElMessage.error('所得税公式有误')
        return
      }
      item.salePriceSuodeTax = math.halfUp(结果, 2)
    }
    const 所得税 = item.salePriceSuodeTax
    console.info('所得税', 所得税)
    item.sumSalePriceTax = math.halfUp(item.salePriceZengzhiTax + item.salePriceFujiaTax + item.salePriceYinhuaTax + item.salePriceSuodeTax, 2)
    item.saleProfitPrice = math.halfUp(item.salePrice - item.sumCostPrice - item.salePriceEstimatedCost - item.sumSalePriceTax, 2)
    item.saleProfitAmount = math.halfUp(item.saleProfitPrice * saleSample.sampleQuantity, 2)
    item.id = saleSample.id
  }
  show.formulaDialog = false
}

const doProfit = () => {
  const updaterList = tabRef.value.getSelectionRows()
  if (updaterList.length <= 0) {
    ElMessage.error('请选择要保存的核算项')
    return
  }
  const saleSampleUpdaterList = updaterList.map(x => x.saleSampleUpdater)
  if (saleSampleUpdaterList.filter(up => !up.saleProfitAmount).length > 0) {
    ElMessage.error('请先应用公式，核算出利润再保存')
    return
  }
  api.post('/backend/saleSample/profit', { saleSampleList: saleSampleUpdaterList }).then(() => {
    ElMessage.success('保存成功')
    commitFindList()
  })
}
</script>

<style lang="less"></style>